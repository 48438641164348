@import '../../../bps/components/teaserGroupTeaser/teaserGroupTeaser.scss';

.teasergroup_teaser {
  $root: &;

  > h2,
  &__headline {
    @media (min-width: $screen-laptop) {
      font-size: $headlineH1FontSize;
    }
  }

  &--float {
    #{$root}__item {
      @media (max-width: $screen-tablet-xsm) {
        width: 100%;
      }
    }
  }

  &--coverflow {
    @media (max-width: $screen-tablet-xsm) {
      padding-left: 0 !important;
      padding-right: 0 !important;
    }
  }

  &--slide {
    @media (max-width: $screen-tablet-xsm) {
      padding-left: 0 !important;
      padding-right: 0 !important;
    }

    .carousel__wrapper {
      padding-left: 10px;
      padding-right: 10px;

      @media (min-width: $screen-mobile-large) {
        padding-left: 15px;
        padding-right: 15px;
      }

      @media (min-width: $screen-tablet-portrait) {
        padding-left: 0;
        padding-right: 0;
      }
    }
  }
}
