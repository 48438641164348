@mixin teaserContainer {
  display: flex;
  flex-flow: column;
}

@mixin teaserHeadline {
  @include fontSize($headlineH2FontSize);
  order: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  line-height: 1.2;
  margin: 0 0 12px;
  font-family: $fontSecondaryFamily;
  font-weight: $headlineH2FontWeight;
  color: $greenDarkColor;

  @media (min-width: $screen-tablet-portrait) {
    margin: 0 0 28px;
  }
}

@mixin teaserLink {
  flex-grow: 1;
  order: 3;
  text-align: center;
  max-width: 100%;
  margin-top: 20px;

  @media (min-width: $screen-tablet-portrait) {
    padding: 0;
    width: 100%;
  }
}

@mixin teaserItemsContainer {
  width: auto;
  order: 2;
  position: relative;

  .tabs-component-panel & {
    margin: 0;
  }
}
