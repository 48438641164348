.simple-image-teaser {
  display: flex;
  justify-content: center;

  &__image {
    width: 100%;
    float: left;
    align-self: center;
  }
}
