.teasergroup_teaser {
  $root: &;
  display: flex;
  flex-flow: row wrap;

  > h2,
  > h3,
  &__headline {
    @include teaserHeadline();

    .headline--text {
      margin: 20px 0 0;
    }
  }

  &__link {
    @include teaserLink();

    @media (min-width: $screen-tablet-portrait) {
      margin-bottom: 18px;
    }

    .button {
      max-width: 100%;
    }
  }

  &__items-container {
    @include teaserItemsContainer();
    width: 100%;
  }

  .tabs-component {
    order: 2;
  }

  // For flex mode
  &--flex {
    #{$root}__items-container.row {
      margin: 0;
    }
  }

  // For float mode
  &--float {
    $marginSize: 7px;

    #{$root}__items-container {
      margin: -$marginSize;
      align-items: stretch;
      flex-grow: 1;
    }

    #{$root}__item {
      padding: $marginSize;

      &--full-image {
        img {
          width: 100%;
        }
      }
    }

    @media (min-width: $screen-tablet-portrait) {
      $marginSize: 15px;

      #{$root}__items-container {
        margin: -$marginSize;
      }

      #{$root}__item {
        padding: $marginSize;
      }
    }

    @media (min-width: $screen-desktop) {
      $marginSize: 20px;

      #{$root}__items-container {
        margin: -$marginSize;
      }

      #{$root}__item {
        padding: $marginSize;
      }
    }
  }

  // For slide mode
  &--slide {
    #{$root}__items-container {
      width: 100%;
    }

    #{$root}__slider-container {
      display: flex;
    }

    #{$root}__item {
      display: inline-block;
      vertical-align: top;
      width: 100%;
    }
  }

  // coverflow mode
  &--coverflow {
    #{$root}__item {
      display: inline-block;
      vertical-align: top;
      width: 100%;
      min-height: 10px;
      transition: transform .6s;
      transform: scale(.65);
    }

    .carousel__slide.swiper-slide-prev,
    .carousel__slide.swiper-slide-duplicate-prev {
      #{$root}__item {
        transform: scale(.8);
      }
    }

    .carousel__slide--active,
    .carousel__slide.swiper-slide-duplicate-active {
      #{$root}__item {
        transform: scale(1);
      }
    }

    .carousel__slide.swiper-slide-next,
    .carousel__slide.swiper-slide-duplicate-next {
      #{$root}__item {
        transform: scale(.8);
      }
    }

    .carousel__slide--active ~ div #{$root}__item {
      transform: scale(.65);
    }

    .image-element {
      display: block;
    }
  }
}

// Placeholder style
/* stylelint-disable-next-line */
teasergroup-teaser-coverflow {
  display: flex;
  justify-content: flex-start !important;
  flex-wrap: nowrap !important;
  overflow: hidden;

  & > * {
    @include componentPreloader();
    min-width: 260px !important;
    min-height: 430px !important;
  }
}

/* stylelint-disable-next-line */
teasergroup-teaser-slider {
  display: flex;
  justify-content: flex-start !important;
  flex-wrap: nowrap !important;
  overflow: hidden;

  & > * {
    @include componentPreloader();
    min-width: 260px !important;
    min-height: 500px !important;
    margin-right: 16px;
  }
}
