.headline-teaser {
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    display: block;
    width: 100%;
    margin: 0;
    font-weight: $fontWeightRegular;

    &:first-child {
      margin-top: 0;
    }
  }

  h1 {
    @include fontSize($fontSizeXXXLarge);
    font-weight: $fontWeightSemiBold;
  }

  h2 {
    @include fontSize($fontSizeXXLarge);
    font-weight: $fontWeightRegular;
  }

  h3 {
    @include fontSize($fontSizeXXXMedium);
    text-transform: $headlineH3TextTransform;
    font-weight: $fontWeightRegular;
  }

  h4,
  h5,
  h6 {
    @include fontSize($fontSizeMedium);
    font-weight: $fontWeightMedium;
  }

  .grid__element--groupedContent1 &,
  .grid__element--groupedContent2 &,
  .grid__element--groupedContent3 & {
    .header--no-p {
      padding: 0;
    }

    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      color: $redDarkColor;

      strong {
        color: $redDarkColor;
      }
    }
  }
}
